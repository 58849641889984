import { useRecoilValue } from 'recoil';
import store from '../../store';
import Message from "./Message";
import { v4 as uuidv4 } from 'uuid';

const MessageList = () => {

    const messages = useRecoilValue(store.messages);

    return (
        <div className="flex-1 overflow-y-auto pt-0">
            <div className="dark:gpt-dark-gray mb-32 h-auto md:mb-48">
                <div className="dark:gpt-dark-gray flex h-auto flex-col items-center text-sm">
                {
                    messages 
                        && messages.map((message, index)  => {
                            const isLast = (index === (messages.length - 1)) ? true : false
                            return <Message key={uuidv4()} message={message} isLastMessage={isLast}/>
                        })
                }
                <div className="dark:gpt-dark-gray group h-0 w-full flex-shrink-0 dark:border-gray-900/50"/>
                </div>
            </div>
        </div>
    );
}

export default MessageList;