
const BASE_URL = "https://mock-openai-test.ihealthlabs.com";
// const BASE_URL = "";

export const login = () => {
    return BASE_URL + '/api/login';
};

export const chat = () => {
    return BASE_URL + '/api/chat';
}

export const updateChat = () => {
    return BASE_URL + '/api/updateChat';
}

export const chatList = () => {
    return BASE_URL + '/api/chatList';
}

export const deleteChat = () => {
    return BASE_URL + '/api/deleteChat';
}

export const getChatById = (id) => {
    return BASE_URL + `/api/getChatById?id=${id}`;
};

export const accountList = (page) => {
    if (page == 0) {
        return BASE_URL + `/api/accountList`;
    } else {
        return BASE_URL + `/api/accountList?page=${page}`;
    }
}

export const createAccount = () => {
    return BASE_URL + '/api/createAccount';
};

export const updateAccount = () => {
    return BASE_URL + '/api/updateAccount';
};

export const deleteAccount = () => {
    return BASE_URL + '/api/deleteAccount';
};