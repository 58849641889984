import LoginForm from './LoginForm';
import { useAuthContext } from '../../hooks/AuthContext';

const Login = () => {

    const { login, errorMessage } = useAuthContext();

    return (
        <div className="flex min-h-screen flex-col items-center justify-center bg-white pt-6 sm:pt-0">
            <div className="mt-6 w-96 overflow-hidden bg-white px-6 py-4 sm:max-w-md sm:rounded-lg">
                <h1 className='mb-4 text-center text-3xl font-semibold'>iHealth Chat</h1>
                {
                    errorMessage && (
                        <div className="relative mt-4 rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
                            role="alert">
                            { errorMessage }
                        </div>
                    )
                }
                <LoginForm onSubmit={login}/>
            </div>
        </div>
    )
}

export default Login;