import { useEffect, useState } from 'react';
import Chat from './Chat';

const ChatList = ({ chatList }) => {

    return (
        <>
            {chatList && chatList.map((chat) => <Chat key={chat.id} id={chat.id} title={chat.title} />)}
        </>
    )
}

export default ChatList;