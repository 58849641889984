import { useForm } from 'react-hook-form';

const CreateForm = ({ onSubmit }) => {

    const { register, handleSubmit } = useForm();

    return (
        <form
            className="mt-6"
            aria-label="Create form"
            method="POST"
            onSubmit={handleSubmit((data) => {
                console.log(data);
                return onSubmit(data);
            })}>
            <div className="mb-2">
                <div className="relative">
                    <input
                        type="text"
                        id="username"
                        autoComplete="username"
                        aria-label="Username"
                        {...register('username', {
                            required: 'Username is required',
                            minLength: {
                              value: 3,
                              message: 'Username must be at least 6 characters'
                            },
                            maxLength: {
                              value: 120,
                              message: 'Username should not be longer than 120 characters'
                            },
                          })}
                        className="peer block w-full appearance-none rounded-t-md border-0 border-b-2 border-gray-300 bg-gray-50 px-2.5 pb-2.5 pt-5 text-sm text-gray-900 focus:border-green-500 focus:outline-none focus:ring-0"
                        placeholder=" ">
                    </input>
                    <label
                        htmlFor="username"
                        className="absolute left-2.5 top-4 z-10 origin-[0] -translate-y-4 scale-75 transform text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-green-500">
                        Username
                    </label>
                </div>
            </div>
            <div className="mb-2">
                <div className="relative">
                    <input
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        aria-label="Password"
                        {...register('password', {
                            required: 'Password is required',
                            minLength: {
                              value: 8,
                              message: 'Password must be at least 8 characters'
                            },
                            maxLength: {
                              value: 40,
                              message: 'Password must be less than 40 characters'
                            }
                          })}
                        className="peer block w-full appearance-none rounded-t-md border-0 border-b-2 border-gray-300 bg-gray-50 px-2.5 pb-2.5 pt-5 text-sm text-gray-900 focus:border-green-500 focus:outline-none focus:ring-0"
                        placeholder=" ">
                    </input>
                    <label
                        htmlFor="password"
                        className="absolute left-2.5 top-4 z-10 origin-[0] -translate-y-4 scale-75 transform text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-green-500">
                        Password
                    </label>
                </div>
            </div>
            <div className="mb-2">
                <div className="relative">
                    <input
                        type="text"
                        id="name"
                        autoComplete="name"
                        aria-label="Name"
                        {...register('name', {
                            required: 'Name is required',
                          })}
                        className="peer block w-full appearance-none rounded-t-md border-0 border-b-2 border-gray-300 bg-gray-50 px-2.5 pb-2.5 pt-5 text-sm text-gray-900 focus:border-green-500 focus:outline-none focus:ring-0"
                        placeholder=" ">
                    </input>
                    <label
                        htmlFor="name"
                        className="absolute left-2.5 top-4 z-10 origin-[0] -translate-y-4 scale-75 transform text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-green-500">
                        Name
                    </label>
                </div>
            </div>
            <div className="mb-2">
                <div className="relative">
                    <input
                        type="text"
                        id="employeeId"
                        autoComplete="employeeId"
                        aria-label="EmployeeId"
                        {...register('employeeId', {
                            required: 'EmployeeId is required',
                          })}
                        className="peer block w-full appearance-none rounded-t-md border-0 border-b-2 border-gray-300 bg-gray-50 px-2.5 pb-2.5 pt-5 text-sm text-gray-900 focus:border-green-500 focus:outline-none focus:ring-0"
                        placeholder=" ">
                    </input>
                    <label
                        htmlFor="employeeId"
                        className="absolute left-2.5 top-4 z-10 origin-[0] -translate-y-4 scale-75 transform text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:text-green-500">
                        EmployeeId
                    </label>
                </div>
            </div>
            <div className="mb-2">
                <div className="relative">
                    <select
                        {...register('role', {
                            required: 'role is required',
                        })}
                        className="peer block w-full appearance-none rounded-t-md border-0 border-b-2 border-gray-300 bg-gray-50 px-2.5 pb-2.5 pt-5 text-sm text-gray-900 focus:border-green-500 focus:outline-none focus:ring-0">
                        <option value="USER">3.5</option>
                        <option value="VIP">4</option>
                    </select>
                    <label
                        htmlFor="role"
                        className="absolute left-2.5 top-4 z-10 origin-[0] -translate-y-4 scale-75 transform text-gray-500">
                        Role
                    </label>
                </div>
            </div>
            <div className="mt-6">
                <button
                    aria-label="Create account"
                    type="submit"
                    className="w-full transform rounded-sm bg-green-500 px-4 py-3 tracking-wide text-white transition-colors duration-200 hover:bg-green-600 focus:bg-green-600 focus:outline-none">
                    Create Account
                </button>
            </div>
        </form>
    );
}

export default CreateForm;