import GPTIcon from '../svg/GPTIcon';

export const getIcon = (role) => {
    if (role === 'user' ) {
        return (
        <div
            title={'User'}
            style={{
                width: 30,
                height: 30}}
            className={'relative flex items-center justify-center'} >
            <img
                className="rounded-sm"
                src={"https://api.dicebear.com/6.x/initials/svg?seed='User'&fontFamily=Verdana&fontSize=36"}
                alt="avatar"/>
        </div>
        )
    } else {
        return (
        <div
            style={{
                background: 'transparent',
                width: 30,
                height: 30}}
            className={'relative flex items-center justify-center rounded-sm text-white'}>
            {<GPTIcon size={25} />}
        </div>
        )
    }
}

export default getIcon;