import { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import ChatList from '../Chat/ChatList';
import NewChat from './NewChat';
import { useChatListQuery, useChatQuery } from '../../data/react-query-service';
import BottomNav from './BottomNav';
import Panel from '../svg/Panel';
import { cn } from '../../utils';
import { useAuthContext } from '../../hooks/AuthContext';
import store from '../../store';

const Nav = ({ navVisible, setNavVisible }) => {
    
    const { logout } = useAuthContext();

    const [isHovering, setIsHovering] = useState(false);
    const [chatList, setChatList] = useState([]);
    const chatId = useRecoilValue(store.chatId);
    const setMessages = useSetRecoilState(store.messages);
    const isRefresh = useRecoilValue(store.isRefresh);

    const useGetChatList = useChatListQuery();
    const useGetChat = useChatQuery(chatId, {
        retry: false, 
        enabled: chatId != null 
    });

    useEffect(() => {
        if (isRefresh) {
            useGetChatList.refetch().then(response => {
                if (response.data.code) {
                    logout();
                    window.location.reload();
                } else {
                    setChatList(response.data);
                }
            })
        }
    }, [isRefresh]);

    useEffect(() => {
        if (chatId != null ) {
            useGetChat.refetch().then(response => {
                if (response.data) {
                    const { messages } = response.data;
                    setMessages(messages);
                }
            })
        }
    }, [chatId])

    const isMobile = () => {
        const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
        const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i;
        return mobileRegex.test(userAgent);
      };
    
    useEffect(() => {
        if (isMobile()) {
            setNavVisible(false);
        } else {
            setNavVisible(true);
        }
    }, [chatId, setNavVisible]);

    const toggleNavVisible = () => {
        setNavVisible((prev) => !prev);
    };

    const theme = 'dark';

    return (
        <>
            <div className={'nav dark bg-gray-900 md:inset-y-0' + (navVisible ? ' active' : '')}>
                <div className="flex h-full min-h-0 flex-col">
                    <div className="scrollbar-trigger relative flex h-full w-full flex-1 items-start border-white/20">
                        <nav className="relative flex h-full flex-1 flex-col space-y-1 p-2">
                            <NewChat />
                            <div className={`flex-1 flex-col overflow-y-auto ${
                                    isHovering ? 'scrollbar-transparent' : 'scrollbar-transparent'
                                } border-b border-white/20`}
                                onMouseEnter={() => setIsHovering(true)}
                                onMouseLeave={() => setIsHovering(false)}>
                                <div className="'flex flex-col gap-2 text-gray-100 text-sm'">
                                    <ChatList chatList={chatList}/>
                                </div>
                            </div>
                            <BottomNav />
                        </nav>
                    </div>
                </div>
                <button
                    type="button"
                    className={cn('nav-close-button -ml-0.5 -mt-2.5 inline-flex h-10 w-10 items-center justify-center rounded-md focus:outline-none focus:ring-white md:-ml-1 md:-mt-2.5', theme === 'dark' ? 'text-gray-500 hover:text-gray-400' : 'text-gray-400 hover:text-gray-500')}
                    onClick={toggleNavVisible}>
                    <span className="sr-only">Close sidebar</span>
                    <Panel/>
                </button>
            </div>
            {!navVisible && (
                <button
                type="button"
                className="nav-open-button fixed left-2 top-0.5 z-10 inline-flex h-10 w-10 items-center justify-center rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-white dark:text-gray-500 dark:hover:text-gray-400"
                onClick={toggleNavVisible}>
                <span className="sr-only">Open sidebar</span>
                <Panel open={true}/>
                </button>
            )}
            <div className={'nav-mask' + (navVisible ? ' active' : '')} onClick={toggleNavVisible}></div>
        </>
        
    )
}

export default Nav;