import { useRecoilState } from 'recoil';
import store from '../../store';
import ConvoIcon from '../svg/ConvoIcon';

const Chat = ({ id, title }) => {

    const [chatId, setChatId] = useRecoilState(store.chatId);

    const aProps = {
        className:
          'animate-flash group relative flex cursor-pointer items-center gap-3 break-all rounded-md bg-gray-800 py-3 px-3 pr-14 hover:bg-gray-800'
      };

    if (chatId !== id) {
        aProps.className =
          'group relative flex cursor-pointer items-center gap-3 break-all rounded-md py-3 px-3 hover:bg-gray-800 hover:pr-4';
    }

    const handleClick = () => {
        if (chatId != id) {
            setChatId(id);
        }
    }

    return (
        <a  onClick={handleClick} {...aProps}>
            <ConvoIcon />
            <div className="relative max-h-5 flex-1 overflow-hidden text-ellipsis break-all">
                {title}
            </div>
            <div className="absolute inset-y-0 right-0 z-10 w-8 rounded-r-md bg-gradient-to-l from-gray-900 group-hover:from-gray-700/70" />
        </a>
    )
}

export default Chat;