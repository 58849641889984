import { createBrowserRouter, Outlet } from 'react-router-dom';
import { Login, Register } from '../components/Auth';
import { Home } from '../components/Home';
import { AuthContextProvider } from '../hooks/AuthContext';
import ApiErrorWatcher from '../components/Auth/ApiErrorWatcher';
import MessageChat from '../components/Message';
import AccountList from '../components/Account';

const AuthLayout = () => (
    <AuthContextProvider>
        <Outlet />
        <ApiErrorWatcher />
    </AuthContextProvider>
);

export const router = createBrowserRouter([
    {
        element: <AuthLayout />,
        children: [
            {
                path: 'login',
                element: <Login />
            },
            {
                path: '/',
                element: <Home />,
                children: [
                  {
                    index: true,
                    element: <MessageChat />
                  },
                  {
                    path: 'account',
                    element: <AccountList />
                  }
                ]
              }
        ]   
    },
]);
