import { useEffect } from "react";
import { useApiError } from "../../hooks/ApiErrorContext";
import { useNavigate } from 'react-router-dom';

const ApiErrorWatcher = () => {

    // const { error } = useApiError();
    // const navigate = useNavigate();

    // useEffect(() => {
    //     if (error?.code === '1000139'
    //         || error?.code === '1000140'
    //         || error?.code === '1000146') {
    //         navigate('/login'); 
    //     }
    // }, [error])
}

export default ApiErrorWatcher;