import { useEffect, useState, Fragment } from 'react';
import { useAccountListQuery, useCreateAccountMutation,useUpdateAccountMutation, useDeleteAccountMutation } from '../../data/react-query-service';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react'
import TrashIcon from '../svg/TrashIcon';
import EditIcon from '../svg/EditIcon';
import UserIcon from '../svg/UserIcon';

import CreateForm from './CreateForm';
import UpdateForm from './UpdateForm';

const AccountList = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [dialogType, setDialogType] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(1);
    const [accountList, setAccountList] = useState([]);
    const [accountId, setAccountId] = useState("");

    const useAccountList = useAccountListQuery(page);
    const useCreateAccount = useCreateAccountMutation();
    const useUpdateAccount = useUpdateAccountMutation(); 
    const useDeleteAccount = useDeleteAccountMutation();
    const navigate = useNavigate();

    useEffect(() => {
        if (useAccountList.data) {
            const { data, total } = useAccountList.data;
            setAccountList(data);
            setPageSize(Math.floor(total / 20) + 1)
        }
    }, [useAccountList.isSuccess, useAccountList.data, page]);

    useEffect(() => {
        useAccountList.refetch()
    }, [page]);
    

    const handleCreate = (type) => {
        setDialogType(type);
        setIsOpen(true);
    }

    const handleUpdate = (type, id) => {
        setDialogType(type);
        setAccountId(id);
        setIsOpen(true);
    }

    const handleClose = () => {
        navigate("/");
    }

    const onCreateSubmit = (data) => {
        useCreateAccount.mutate(data, {
            onSuccess: (data) => {
                console.log(data);  
            },
        });
        setIsOpen(false);
    }

    const onUpdateSubmit = (data) => {
        data.id = accountId;
        useUpdateAccount.mutate(data, {
            onSuccess: (data) => {
                console.log(data);  
            },
        });
        setIsOpen(false);
    }

    const handleDelete = (id) => {
        console.log(id)
        useDeleteAccount.mutate({ id }, {
            onSuccess: (data) => {
                console.log(data);  
            },
        });
        setIsOpen(false);
    } 

    const heads = ['Username', 'Name', 'EmployeeId', 'Operation']

    return (
        <div className='ml-20 overflow-y-auto'>
            <table className="w-full pr-10 table text-gray-400 border-separate space-y-6 text-sm">
                <thead className="bg-gray-800 text-gray-200">
                    <tr>
                    {
                        heads.map((head) => (<th key={head} className='p-3 text-left'>{head}</th>))
                    }
                    </tr>
                </thead>
                <tbody>
                    { accountList && accountList.map(({ id, username, name, employeeId }) => {
                        return (<tr key={id} className="bg-gray-900 mt-3">
                                    <td className='p-3'>{username}</td>
                                    <td className='p-3'>{name}</td>
                                    <td className='p-3'>{employeeId}</td>
                                    <td className='p-3'>
                                        <div className='flex space-x-5'>
                                            <a onClick={() => handleUpdate(2, id)}> 
                                                <EditIcon />
                                            </a>
                                            <a onClick={() => handleDelete(id)}>
                                                <TrashIcon />
                                            </a>
                                        </div>
                                        
                                    </td>
                                </tr>)
                    })}
                </tbody>
            </table>

            <div className="flex pr-10 justify-end space-x-1">
                <button
                    onClick={ () => {  handleCreate(1) }}
                    className="rounded-md bg-gray-900 text-white px-10 py-3">
                    New
                </button>
                <button
                    onClick={ () => { handleClose() }}
                    className="rounded-md bg-gray-900 text-white px-10 py-3">
                    Close
                </button>
                <button
                    onClick={ () => { page > 1 ? setPage(page - 1) : setPage(1) }}
                    className="rounded-md bg-gray-900 hover:bg-gray-900 text-white px-10 py-3">
                    Last
                </button>
                <button
                    onClick={ () => { page > pageSize ? setPage(pageSize) : setPage(page + 1) }}
                    className="rounded-md bg-gray-900 text-white px-10 py-3">
                    Next
                </button>
                <p  
                    className="rounded-md text-white px-10 py-3">
                    {page}/{pageSize}
                </p>
            </div>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => { setIsOpen(false) }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900">
                                    { dialogType == 1 ? "New account" : "Update account" }
                                </Dialog.Title>
                                { dialogType == 1 ? <CreateForm onSubmit={onCreateSubmit}/> : <UpdateForm onSubmit={onUpdateSubmit}/> }
                            </Dialog.Panel>
                        </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
}

export default AccountList;