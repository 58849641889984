import { Menu, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import DotsIcon from '../svg/DotsIcon';
import NavLink from './NavLink';
import GearIcon from '../svg/GearIcon';
import UserIcon from '../svg/UserIcon';
import Logout from './Logout';
import { useNavigate } from 'react-router-dom';

const BottomNav = () => {

    const navigate = useNavigate();

    const access = localStorage.getItem('ihealth-chat-access');
    console.log(access);

    return (
        <Menu as="div" className="group relative">
            <Menu.Button className="'group-ui-open:bg-gray-800 flex w-full items-center gap-2.5 rounded-md px-3 py-3 text-sm transition-colors duration-200 hover:bg-gray-800'">
                <div className="-ml-0.5 h-5 w-5 flex-shrink-0">
                <div className="relative flex">
                  <img
                    className="rounded-sm"
                    src={ `https://api.dicebear.com/6.x/initials/svg?seed=User}&fontFamily=Verdana&fontSize=36`}
                    alt=""/>
                    </div>
                </div>
                <div className="grow overflow-hidden text-ellipsis whitespace-nowrap text-left text-white">
                    {'USER'}
                </div>
                <DotsIcon />
            </Menu.Button>
            <Transition 
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute bottom-full left-0 z-20 mb-2 w-full translate-y-0 overflow-hidden rounded-md bg-[#050509] py-1.5 opacity-100 outline-none">
                    {
                        access && access.includes('ACCOUNT') && (
                            <Menu.Item as="div">
                                <NavLink
                                    className="flex w-full cursor-pointer items-center gap-3 px-3 py-3 text-sm text-white transition-colors duration-200 hover:bg-gray-700 rounded-none"
                                    svg={() => <UserIcon />}
                                    text="account"
                                    clickHandler={ () => { 
                                        navigate('/account'); 
                                    } }
                                />
                            </Menu.Item>    
                        )
                    }
                    <Menu.Item as="div">
                        <Logout />
                    </Menu.Item>
                </Menu.Items>
                
            </Transition>
        </Menu>  
    )
}

export default BottomNav;