import { useMutation, useQueryClient, useQuery } from "react-query";
import * as request from './request';
import * as api from './api';

export const QueryKeys = {
    user: 'user',
    chatList: 'chatList',
    chat: 'chat',
    accountList: 'acountList'
}

export const useLoginUserQuery = () => {
    const queryClient = useQueryClient();
    return useMutation((payload) => request.post(api.login(), payload), {
        onSuccess: (data) => {
            console.log(data);
        }
    })
}

export const useChatListQuery = () => {
    return useQuery({
        queryKey: ['chatList'],
        queryFn: () => request.get(api.chatList()),
    })
}

export const useChatQuery = (id, config) => {
    return useQuery({
        queryKey: ['chat'],
        queryFn: () => request.get(api.getChatById(id)),
        ...config
    })
}

export const useNewChatMutation = () => {
    const queryClient = useQueryClient();
    return useMutation((payload) => request.post(api.chat(), payload), {
        onSuccess: (data) => {
            console.log(data);
        }
    })
}

export const useUpdateChatMutation = () => {
    const queryClient = useQueryClient();
    return useMutation((payload) => request.post(api.updateChat(), payload), {
        onSuccess: (data) => {
            console.log(data);
        }
    })
}

export const useAccountListQuery = (page) => {
    return useQuery({
        queryKey: ['acountList'],
        queryFn: () => request.get(api.accountList(page)),
        retry: false
    })
}

export const useCreateAccountMutation = () => {
    const queryClient = useQueryClient();
    return useMutation((payload) => request.post(api.createAccount(), payload), {
        onSuccess: (data) => {
            console.log(data);
        }
    })
}

export const useUpdateAccountMutation = () => {
    const queryClient = useQueryClient();
    return useMutation((payload) => request.post(api.updateAccount(), payload), {
        onSuccess: (data) => {
            console.log(data);
        }
    })
}

export const useDeleteAccountMutation = () => {
    const queryClient = useQueryClient();
    return useMutation((payload) => request.post(api.deleteAccount(), payload), {
        onSuccess: (data) => {
            console.log(data);
        }
    })
}
