
import React, { useRef, useEffect } from 'react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import store from '../../store';
import Footer from './Footer';
import TextareaAutosize from 'react-textarea-autosize';
import SubmitButton from './SubmitButton';
import { useNewChatMutation, useUpdateChatMutation, useChatListQuery } from '../../data/react-query-service';

const InputChat = () =>  {
    const setIsSubmitting = useSetRecoilState(store.isSubmitting);
    const [textMessage, setTextMessage] = useRecoilState(store.textMessage);
    const [chatId, setChatId] = useRecoilState(store.chatId);
    const [messages, setMessages] = useRecoilState(store.messages);
    const setIsRefresh = useSetRecoilState(store.isRefresh);

    const useNewChat = useNewChatMutation();
    const useUpdateChat = useUpdateChatMutation();

    const inputRef = useRef(null);
    const disabled = false;

    const handleChange = (e) => {
        const { value } = e.target;
        setTextMessage(value);
    }

    const handleSubmit = () => {
        const payload = { message: textMessage };
        let arr = [];
        if (messages) {
            arr = [...messages];
        }
        arr.push({
            role: 'user',
            content: textMessage
        })
        arr.push({
            role: 'test',
            content: ''
        })
        setIsSubmitting(true);
        setMessages(arr);
        if (chatId) {
            payload.id = chatId;
            useUpdateChat.mutate(payload, {
                onSuccess: (data) => {
                    if (data.messages) {
                        setMessages(data.messages);
                    }
                    setIsSubmitting(false);
                }
            })
        } else {
            setIsRefresh(false);
            useNewChat.mutate(payload, {
                onSuccess: (data) => {
                    if (data.messages) {
                        setChatId(data.id);
                        setMessages(data.messages)
                    }
                    setIsSubmitting(false);
                    setIsRefresh(true);
                }
            })
        }
        setTextMessage("");
    }

    return (
        <div className="fixed bottom-0 left-0 w-full md:absolute">
            <div className="input-panel md:bg-vert-light-gradient dark:md:bg-vert-dark-gradient relative w-full border-t bg-white py-2 dark:border-white/20 dark:bg-gray-800 md:border-t-0 md:border-transparent md:bg-transparent md:dark:border-transparent md:dark:bg-transparent">
                <form className="stretch mx-2 flex flex-row gap-3 last:mb-2 md:pt-2 md:last:mb-6 lg:mx-auto lg:max-w-3xl lg:pt-6">
                    <div className="relative flex h-full flex-1 md:flex-col">
                    <div className={`relative flex flex-grow flex-row rounded-md border border-black/10 ${
                                    disabled ? 'bg-gray-100' : 'bg-white'
                                    } py-2 shadow-[0_0_10px_rgba(0,0,0,0.10)] dark:border-gray-900/50 ${
                                    disabled ? 'dark:bg-gray-900' : 'dark:bg-gray-700'
                                    } dark:text-white dark:shadow-[0_0_15px_rgba(0,0,0,0.10)] md:py-3 md:pl-4`}>
                            <TextareaAutosize
                                className="m-0 flex h-auto max-h-52 flex-1 resize-none overflow-auto border-0 bg-transparent p-0 pl-2 pr-12 leading-6 placeholder:text-sm placeholder:text-gray-600 focus:outline-none focus:ring-0 focus-visible:ring-0 dark:bg-transparent dark:placeholder:text-gray-500 md:pl-2" 
                                data-testid="text-input"
                                tabIndex="0"
                                autoFocus
                                ref={inputRef}
                                rows="1"
                                value={textMessage}
                                onChange={handleChange}
                            />
                            <SubmitButton submitMessage={handleSubmit}/>
                        </div>
                    </div>
                </form>
                <Footer />
            </div>
        </div>
    )
}

export default InputChat;