import axios from 'axios';

export const get = async (url) => {
    console.log("get", url);
    const Authorization = localStorage.getItem('ihealth-chat-token');
    const response = await axios.get(url, { headers: { Authorization } });
    // const response = await axios.get(url);
    return response.data;
}

export const post = async (url, data) => {
    console.log("post", url, data);
    const response = await axios.post(url, JSON.stringify(data), {
        headers: { 'Content-Type': 'application/json' }
    });
    return response.data;
}

