import { useState } from 'react';
import Nav from '../Nav';
import MobileNav from '../Nav/MobileNav';
import { Outlet } from 'react-router-dom';

const Home = () => {

    const [navVisible, setNavVisible] = useState(false);
    return (
        <>
            <div className="flex h-screen">
                <Nav navVisible={navVisible} setNavVisible={setNavVisible}/>
                <div className="flex h-full w-full flex-1 flex-col bg-gray-50">
                    <div className="transition-width relative flex h-full w-full flex-1 flex-col items-stretch overflow-hidden bg-white pt-10 dark:bg-gray-800 md:pt-0">
                        <MobileNav setNavVisible={setNavVisible} />
                        <Outlet />
                    </div>
                </div>
            </div>
        </>  
    )
}

export default Home;