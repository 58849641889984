import getIcon from './getIcon';
import Content from './Content/Content';
import { cn } from '../../utils'

const Message = ({ message, isLastMessage }) => {

    const { role, content } = message;

    const props = { className: 'w-full border-b border-black/10 dark:border-gray-900/50 text-gray-800 bg-white dark:text-gray-100 group dark:bg-gray-800' };

    if ( role === 'user') {
        props.className = 'w-full border-b border-black/10 bg-gray-50 dark:border-gray-900/50 text-gray-800 dark:text-gray-100 group bg-gray-100 dark:bg-gray-1000';
    }

    const icon = getIcon(role);
    
    return (
        <div {...props}>
            <div className="relative m-auto flex gap-4 p-4 text-base md:max-w-2xl md:gap-6 md:py-6 lg:max-w-2xl lg:px-0 xl:max-w-3xl">
                <div className="relative flex h-[30px] w-[30px] flex-col items-end text-right text-xs md:text-sm">
                    {icon}
                </div>
                <div
                    className={cn(
                      'flex min-h-[20px] flex-grow flex-col items-start gap-4 ',
                      role === 'user' ? 'whitespace-pre-wrap' : ''
                    )}>
                    <div className="markdown prose dark:prose-invert light w-full break-words">
                      {role === 'user' ? (
                        <>
                          <>{content}</>
                        </>
                      ) : (
                        <Content content={content} message={message} isLastMessage={isLastMessage}/>
                      )}
                    </div>
                  </div>
            </div>
        </div>
    )
}

export default Message;