import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoginUserQuery } from '../data/react-query-service';
import { setTokenHeader } from '../data' 

const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {

    const [errorMessage, setErrorMessage] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    
    const navigate = useNavigate();
    const loginUser = useLoginUserQuery();

    const setUserContext = (data) => {
        const { id, accessToken, access } = data;
        localStorage.setItem('ihealth-chat-token', accessToken);
        localStorage.setItem('ihealth-chat-access', access);
        setTokenHeader(accessToken);
        setIsAuthenticated(true);
    }

    const login = (data) => {
        loginUser.mutate(data, {
            onSuccess: (data) => {
                console.log(data);
                if (data.message) {
                    setErrorMessage(data.message);
                } else {
                    setUserContext(data);
                }
            },
            onError: (error) => {
                setErrorMessage(error);
            },
        })
    }

    const logout = () => {
        localStorage.removeItem('ihealth-chat-token');
    }
    
    useEffect(() => {
        const tokenFromLocal = localStorage.getItem('ihealth-chat-token');
        console.log(tokenFromLocal)
        if (tokenFromLocal == null) {
            navigate('/login');
        } else {
            console.log('/');
            setTokenHeader(tokenFromLocal);
            navigate('/');
        }
    }, [isAuthenticated])

    const memoedValue = useMemo(() => ({
        login,
        logout,
        errorMessage
    }), [errorMessage])
    
    return (
        <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
    )
}

const useAuthContext = () => {
    const context = useContext(AuthContext);
    return context;
}

export {
    AuthContextProvider,
    useAuthContext
}