import InputChat from "./InputChat";
import MessageList from "./MessageList";

const MessageChat = () => {

    return (
        <>
            <MessageList />
            <InputChat />
        </>
    );
}

export default MessageChat;