import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query';
import { RecoilRoot } from 'recoil';
import { router } from './routes';
// import { useApiError } from './hooks/ApiErrorContext';

const App = () => {

  // const { setError } = useApiError();

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      // onSuccess: (data) => {
      //   console.log(data)
      //   if (data?.code) {
      //     setError({
      //       code: data.code,
      //       message: data.message
      //     })
      //   }
      // },
      onError: (error) => {
        console.log(error);
      }
    })
  });

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <RouterProvider router={router} />
      </RecoilRoot>
    </QueryClientProvider>
  );
}

export default App;
