import { atom } from 'recoil';

const textMessage = atom({
  key: 'textMessage',
  default: ''
});

const messages = atom({
  key: 'messages',
  default: []
});

const chatId = atom({
  key: 'chatId',
  default: null
});

const isSubmitting = atom({
  key: 'isSubmitting',
  default: false
});

const isRefresh = atom({
  key: 'isRefresh',
  default: true
});

const chatList = atom({
  key: 'chatList',
  default: []
})


export default { 
  textMessage,
  messages, 
  chatId,
  isSubmitting,
  isRefresh,
  chatList
};